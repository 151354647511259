import axios from 'axios';

const setupApiAuth = () => {
  const csrfTokenElem = document.querySelector("meta[name='csrf-token']");
  const jwtElem = document.querySelector("meta[name='jwt']");

  if(csrfTokenElem !== null){
    const csrf = csrfTokenElem.getAttribute('content');
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf;
  }

  if(jwtElem !== null){
    const jwt = jwtElem.getAttribute('content');
    axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
  }

};

document.addEventListener('DOMContentLoaded', function() {
  setupApiAuth();
});

document.addEventListener('turbolinks:load', function(event) {
  setupApiAuth();
});
