import { I18n } from 'i18n-js';

import translations from '../../../public/i18n/translations.json';
import { configure } from '../routes';

const locale = document.documentElement.lang ?? 'en';
export const i18n = new I18n(translations);
i18n.store(translations);
i18n.defaultLocale = 'en';
i18n.enableFallback = true;
i18n.locale = locale;
window.I18n = i18n; // Backwards compat

// Set js-routes default locale
configure({
  default_url_options: { locale },
});
