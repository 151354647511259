import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';

const DefComponent = () => null

export const AsyncComponent = ({componentPath, DefaultComponent, ...props}) => {
  // see https://webpack.js.org/api/module-methods/#magic-comments
  const LazyComponent = lazy(() => import(
      /* webpackChunkName: "react__async__[request]" */
      /* webpackInclude: /\.js$/ */
      /* webpackMode: "lazy" */
      `renderable/${componentPath}`)
  );
  return (
      <Suspense fallback={<DefaultComponent {...props}/>}>
        <LazyComponent {...props} />
      </Suspense>
  )
}

AsyncComponent.propTypes = {
  componentPath: PropTypes.string.isRequired,
  DefaultComponent: PropTypes.elementType,
}

AsyncComponent.defaultProps = {
  DefaultComponent: DefComponent,
}