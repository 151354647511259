import * as Sentry from '@sentry/react';

if (SENTRY_PUBLIC_DSN !== '') {
  try {
    Sentry.init({
      dsn: SENTRY_PUBLIC_DSN,
      environment: RAILS_ENV,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [new Sentry.Replay()],
      allowUrls: ['localhost', /^https?:\/\/((.*)\.)?nodalview\.com/],
      ignoreErrors: [
        // See: https://nodalview-sa.sentry.io/issues/4277402741/events/d305b9420a5a42f685b49854a451be11/
        // and: https://stackoverflow.com/a/50387233
        /ResizeObserver loop/,
        // See: https://nodalview-sa.sentry.io/issues/4280257552/events/6326c9b27dd14e6fbb8bc75c769c6659/
        "Failed to execute 'isSessionSupported' on 'XRSystem'",
        "Failed to execute 'isSessionSupported' on 'XR'",
        // See: https://nodalview-sa.sentry.io/issues/4354653196/events/942f88d9d2c14433b1c8330e5055e1ef/
        /Loading chunk/,
        // See: https://nodalview-sa.sentry.io/issues/4354860415/events/08cbd5581086431bb020e1288268852c/
        'NS_ERROR_NOT_INITIALIZED',
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        'Unhandled Promise Rejection: Load failed',
        'A delayRender()',
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });

    const userUuid = document.querySelector('meta[name="user"]')?.getAttribute('content');
    if (userUuid) {
      Sentry.setUser({ id: userUuid });
    }
  } catch (err) {
    console.error('Sentry init failed with error', err);
  }
}
